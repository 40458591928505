import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "DMT - Beyond Perception",
  "date": "2019-02-10T00:00:00.000Z"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Finally, that manifestation came true after, indeed at the least expected time and in the most surprising way. My craving for synthetic DMT has a deep-rooted history, ever since I started researching Entheogens; DMT was my superior favorite. I spent countless effort and time doing tek experiments with natural substances (Mimosa Hostlis, Phalaris, Psychotria, Yopo, Chacruna, etc). Even though many of the experience was worthy, none was able to provide the profoundness of synthetic DMT.`}</p>
    <p>{`My experience with Ayahausca was not as colorful as intended, mostly due to multiple reasons which I curated in my post regarding Aya. `}</p>
    <p>{`Even after multiple failed attempts, my curiosity about this magic element was increasing every day.
Honestly speaking at one point, I forget about my desire, I stopped craving it and as usual, the magic happens. `}</p>
    <p>{`On an unexpected visit to my homies house in Electronic City, I met this guy from Goa who randomly asked "Bro you want DMT"? `}</p>
    <p>{`I was shocked to hear that, w/o having a moment I answered, "yea bro sure"`}</p>
    <p>{`He handed over an aluminum pouch of approx .80g of crystal N,N DMT. He insisted to take a small shot, but I politely hesitated to know the consequence. I paid him $190 which I think was one of the fair deals I came across ever. `}</p>
    <p>{`Upon reaching home I rushed to take my very first hit. I cleaned the bong and made a sandwich ( lemon haze + DMT). I took approx 0.10g to check my resistance. I lighted the bong and held my breath in my lungs until I get the first kick. `}</p>
    <p>{`Within 5 seconds my entire vision got changed into colorful patterns, moving and evolving. I can feel the Prana over the whole body, a sudden surge of happiness and peace, followed by geometrical patterns and shapes. Everything appeared to be a perpetual cyclic moving, continuously flowing. `}</p>
    <p>{`I was sitting in my chair completely relaxed and without having any awareness of myself or my environment. All my five senses were out of my awareness. I was unable to see any Maya, I can't hear anything, I had no memory, I had no sense of feeling.
All I was aware of was the Prana. The whole trip was not similar to any of my previous experiences.
Visuals began intense and consistent while focusing on the breath, which I couldn't follow for more than 30sec. The trip lasts for 15mins with 3 intense kicks in between, and there was no hangover which surprise me the more. `}</p>
    <p>{`After 24 hours I bonged 0.50g DMT, indeed the trip was very intense and vivid. I am seeing colorful patterns and shapes growing and growing. I am hearing earthly sounds and can feel the energy surge in the Pineal gland. My first hit was the peak, with a sense of nothingness; complete void but it last for only a few mins followed by visuals. While focusing on my breath, I am getting blown out more and more. I can feel an overwhelming sense of oneness, with my boundaries getting dissolved and my chakras getting opened up. The Kundalini energy surge from Mooladara to Sahasrara can be felt in under a minute. The flow of Pranas through all nadies make an ecstatic feeling, accompanied by ever-changing visuals and patterns. Once regained my awareness my thinking process initiated and I am witnessing my thoughts, all moments, events, and everything flashing in front of me. Over the whole trip, I never felt Time exists, Only the spatial aspect can be experienced. `}</p>
    <p>{`Many of the questions got answered, and Most of my doubts got cleared. TBH I don't know who was answering all these,
but indeed I experienced this godly or divine power who was answering all my queries. `}</p>
    <p>{`As I mentioned earlier DMT trip is not something that can be comprehended using mere language. It is a heavenly experience, and I should acknowledge the fact that DMT is omnipresent; everywhere including in the human body it exists. Our pineal gland secrets DMT in deep sleep,  trance,  tantrics and mantrics, yogas, etc. Almost in any activity while concentrating on the awareness or the prana for a prolonged period. In deep sleep, there is no sense of Ego, nor awareness of the five senses.
Only breathing exists. `}</p>
    <p>{`My experience with synthetic DMT is very limited (7 times) mostly due to the difficulty in sourcing even though I believe at the right time, I will get more access. From all my experiences with DMT (synthetic and natural), I believe the vision and experience of DMT is the reality, unlike ordinary reality 'Maya'; which is temporary and ever-changing, the vision of DMT is more appealing to be the only reality that prevails in the universe. `}</p>
    <p>{`Every DMT experience was fresh unlike other psychedelics, it is smooth and natural. The friction between the body, soul, and mind is not felt across the trip, There was no boundary between the Doer, the process, and the witness. None of the other psychedelics facilitates this kind of experience, even though 5-Meo-DMT reflects the absolute state of reality, and Psyclobins and LSD colorfully portray the universal reality. `}</p>
    <p>{`My experience with Meditation has somewhat similar feelings to a mild DMT trip, which of course makes sense. We can precisely say all the Rishis and Yogis meditating for numerous years is having a more profound DMT experience. Blowing out Shasrara chakra represents the opening of 1000 petal lotus in the crown, and the surge of kundalini shakti from the root chakra Pierces the Ajna chakra resulting in increased perception, awareness, and spiritual communication. `}</p>
    <p>{`A meditating person can experience the DMT trip if he was able to unlock the Ajna chakra, and of course, it requires a tremendous amount of work. Since this chakra is known as "the seat of intuition", secretion of DMT gives lots of mystical and magical experiences. `}</p>
    <p>{`I would certainly try this Business man's drug any other day. If the whole world smoked DMT, the world becomes Shambala. `}</p>
    <p>{`For me DMT is a go-to herb, for any seeking, self-inquiry, designs, developments, healing and you name it. I had experienced multiple gross events with the best outcomes and wonderful results. `}</p>
    <p>{`While on DMT one can feel the divine unity of Shiva and Shakti, Yin and Yang. Life starts to flow, unwinding slowly, submerging in tranquility and awareness. Anyone can witness the unmanifested becoming manifested right upon our front. AFAIK the ultimate reality that everyone is speaking of and seeking is somewhat similar ( not exactly visuals) but while reaching a higher consciousness with all chakras working actively, one can witness the 'supreme reality, the ultimate Brahman, in its unmanifested form. Where there's no form and shape, No patterns and visuals, No colors and lights; it's absolute darkness, ultimate void, complete nothingness. `}</p>
    <p>{`My experience with 5-Meo-DMT can be read here.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      